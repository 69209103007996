<template>
    <div class="text-center m-0">
        <v-menu offset-y bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    elevation="0"
                    v-on="on"
                    v-bind="{ attrs }"
                    :loading="isLoading"
                >
                    <v-icon color="white" small>{{mainIcon}}</v-icon>
                    <span class="body-1 white--text font-weight-medium text-capitalize">
                        {{ text }}
                    </span>
                </v-btn>
            </template>

            <v-list>
                <v-list-item v-for="(option, index) in formatOptions" :key="index" class="w-100">
                    <v-row class="w-100">
                        <v-btn
                            text
                            color="deep-purple"
                            class="w-100 text-align-start d-flex align-items-start justify-content-start py-3"
                            @click="option.action"
                        >
                            <v-row align-content="center" justify="start" class="text-start pa-3">
                                <v-icon small class="mr-1">
                                    {{ option.icon }}
                                </v-icon>
                                {{ option.text }}
                            </v-row>
                        </v-btn>
                    </v-row>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'DropdownButton',
    props: {
        formatOptions: {
            type: Array,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        mainIcon: {
            type: String,
            required: false,
            default: "mdi-download",
        },
    },
    data() {
        return {
            isLoading: false,
        }
    },
}
</script>
